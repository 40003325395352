import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Col, Container, Row } from "reactstrap";
import ContactInfo from "./ContactInfo";
import MapSection from "./MapSection";

function Taquillas() {
  const firstBreadcrumb = { label: "Pages", link: "/contact-us-2" };
  const secondBreadcrumb = {
    label: "Taquillas",
    link: "/taquillas",
    active: true,
  };
  const contactInfo = [
    {
      region: "Apartadó (Micro busetas)",
      description: "Terminal de Transporte Local 001",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.168111278043!2d-76.6386314240738!3d7.877472205948255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e500d7ecf23e32b%3A0xe51414fa7dcf6e99!2sTerminal%20de%20Transporte%20de%20Apartad%C3%B3!5e0!3m2!1ses!2sco!4v1692408031798!5m2!1ses!2sco",
      phone: '3113890919',
      email: "",
    },
    {
      region: "Apartadó (Camionetas)",
      description: "Terminal de Transporte Local 005",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.168111278043!2d-76.6386314240738!3d7.877472205948255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e500d7ecf23e32b%3A0xe51414fa7dcf6e99!2sTerminal%20de%20Transporte%20de%20Apartad%C3%B3!5e0!3m2!1ses!2sco!4v1692408031798!5m2!1ses!2sco",
      phone: '3113903058',
      email: "",
    },
    {
      region: "Apartadó (Taxis)",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.168111278043!2d-76.6386314240738!3d7.877472205948255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e500d7ecf23e32b%3A0xe51414fa7dcf6e99!2sTerminal%20de%20Transporte%20de%20Apartad%C3%B3!5e0!3m2!1ses!2sco!4v1692408031798!5m2!1ses!2sco",
      phone: '3015208937',
      email: "",
    },
    {
      region: "Carepa",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.2924545745846!2d-76.65985672987605!3d7.758775918368592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5011ccc9859b81%3A0xf78379b727cdd6b5!2sCoointur!5e0!3m2!1ses-419!2sco!4v1695453070545!5m2!1ses-419!2sco",
      phone: '3107102846',
      email: "",
    },
    {
      region: "Monteria (Micro busetas)",
      description: "Terminal de Transporte Taquilla 15A",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1394.1928911643456!2d-75.86428229737317!3d8.75093178484275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5a2f90206c1dc3%3A0x92019242aa36fca7!2sTERMINAL!5e0!3m2!1ses!2sco!4v1692406511772!5m2!1ses!2sco",
      phone: '3113898778',
      email: "",
    },
    {
      region: "Monteria (Camionetas - MINIVANS)",
      description: "Terminal de Transporte Taquilla 19B",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1394.1928911643456!2d-75.86428229737317!3d8.75093178484275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5a2f90206c1dc3%3A0x92019242aa36fca7!2sTERMINAL!5e0!3m2!1ses!2sco!4v1692406511772!5m2!1ses!2sco",
      phone: '3113898660',
      email: "",
    },
    {
      region: "Turbo",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d987.5117242640126!2d-76.72287070306791!3d8.096700225206042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5065fa7af5b507%3A0xaa84ff16ced8892a!2sCra.%2019%20%26%20Turbo-Apartad%C3%B3%2C%20Turbo%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1692418780094!5m2!1ses!2sco",
      phone: '3113898741',
      email: "",
    },
    {
      region: "Necocli",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.7549623732198!2d-76.78510682407143!3d8.425708198138748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e508d785459570f%3A0x8ef1278b8b56849!2sCl.%2052%20%26%20Cra.%2044%2C%20Necocl%C3%AD%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1692419124540!5m2!1ses!2sco",
      phone: '3113898791',
      email: "",
    },
    {
      region: "Chigorodó",
      description: "Terminal de Transporte Local 154",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.125352266394!2d-76.68162792407452!3d7.669670408781963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e501b7ba1a496a7%3A0x4294d7e1c9159a5!2sTerminal%20de%20Transporte%20de%20Chigorod%C3%B3!5e0!3m2!1ses!2sco!4v1692420100720!5m2!1ses!2sco",
      phone: '3113894708',
      email: "",
    },
    {
      region: "Mutatá",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.9673666448116!2d-76.43997472407605!3d7.244553614362515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e455fe894355f77%3A0x34a733b86acc42ff!2sPlaza%20De%20Mercado%20Mutat%C3%A1!5e0!3m2!1ses!2sco!4v1692421069162!5m2!1ses!2sco",
      phone: '3116096236',
      email: "",
    },
    {
      region: "Arboletes",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.3018717939794!2d-76.43163431450729!3d8.851462169076253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5a00ccadbcfe69%3A0xb471c10c95d000d7!2sTransportes%20COOINTUR!5e0!3m2!1ses!2sco!4v1692421649872!5m2!1ses!2sco",
      phone: '3113894708',
      email: "",
    },
    {
      region: "Arboletes (Especiales)",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.3018717939794!2d-76.43163431450729!3d8.851462169076253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5a00ccadbcfe69%3A0xb471c10c95d000d7!2sTransportes%20COOINTUR!5e0!3m2!1ses!2sco!4v1692421649872!5m2!1ses!2sco",
      phone: '3113894708',
      email: "",
    },
    {
      region: "San Juan de Urabá",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d246.4544702086253!2d-76.52827837682582!3d8.76050411197598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1scalle%20principal%2C%20San%20Juan%20de%20Urab%C3%A1%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1692455332366!5m2!1ses!2sco",
      phone: '3113898768',
      email: "",
    },
    {
      region: "Belén de Bajirá",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.8439337624923!2d-76.71957636458!3d7.371383293763623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4fef21664dab69%3A0xb35ce1049d4a8198!2sBelen%20de%20Bajira%2C%20Mutat%C3%A1%2C%20Antioquia!5e0!3m2!1ses-419!2sco!4v1695452591489!5m2!1ses-419!2sco",
      phone: '3113898773',
      email: "",
    },
    {
      region: "Rio Sucio",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.1790306426496!2d-76.67835510431335!3d7.6638924394258945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e501b598685a725%3A0xc6cdc29c550d87f4!2sBarrio%20para%C3%ADso!5e0!3m2!1ses-419!2sco!4v1695452875353!5m2!1ses-419!2sco",
      phone: '3116217977',
      email: "",
    },
    {
      region: "Tierra Alta",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1660.483344537327!2d-76.05978977920688!3d8.170762237967548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1ses-419!2sco!4v1695453854384!5m2!1ses-419!2sco",
      phone: '3113898702',
      email: "",
    },
    {
      region: "Valencia",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d246.77779454971042!2d-76.14805784044349!3d8.258454425544796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sco!4v1695454492486!5m2!1ses-419!2sco",
      phone: '3113898680',
      email: "",
    },
    {
      region: "San Pedro de Urabá",
      description: "",
      address: "",
      adresssrc: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d415.0085682306963!2d-76.38329099081186!3d8.277984487010984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sco!4v1695454765707!5m2!1ses-419!2sco",
      phone: '3113890921',
      email: "",
    },

  ];
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Taquillas"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <section>
        <div className="container">
          <div className="row d-flex justify-content-between">
            <ContactInfo contactInfo={contactInfo} />
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row className="mt-4">
           { <Col>
              <h5 >Recepción - Apartadó</h5>
            </Col>
           }
            <Col lg={12} className="col-12">
              <MapSection adresssrc={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d494.021319316547!2d-76.6313802!3d7.8772162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e500d79a358370b%3A0x96ba73487120d9d0!2sCl.%2091%20%2396a-27%2C%20Apartad%C3%B3%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1691360768804!5m2!1ses!2sco"} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Taquillas;
