import React from "react";

const MapSection = ({adresssrc}) => {
  return (
    <div className="map">
      <iframe
        className="w-100 h-100 border-0"
        src={adresssrc}
        allowFullScreen=""
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default MapSection;
