import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

function About2() {
  return (
    <div>
      <section className="p-4">
        <Container>
          <Row className="align-items-center" >
            <Col xs={12} lg={10} className="pt-10 pb-10">
              <h6 className="border-bottom border-dark border-2 d-inline-block mb-3">
                Acerca de nosotros
              </h6>
              <h2 className="font-w-6">
                Nuestra Historia
              </h2>
              <p className="lead mb-4">
                COOINTUR, fue creada en el Municipio de Necocli - Antioquia el 14 de febrero de 1983, por 49 asociados. Todos ellos pequeños transportadores de la Zona Norte del Urabá Antioqueño, con el nombre de Cooperativa Integral de Transportadores Rurales de Urabá.
              </p>

              <Link className="btn btn-success  rounded-2 mt-6" to="/quienes-somos" style={{backgroundColor:'#5ed15e !important', boxShadow:'5px 5px 5px 0px rgba(0,0,0,0.3)', border: 'none'}}>
                Leer más
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default About2;
