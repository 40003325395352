import React from "react";
import { Row, Col } from "reactstrap";
import MapSection from "./MapSection";

const ContactInfo = ({ contactInfo }) => {
  return contactInfo.map((info, index) => (
      <div key={index} className=" col-lg-4 col-md-12 col-sm-12">
        <h6>{info.region}</h6>
        <p>{info.description}</p>
          <i className="text-primary fs-4 align-middle bi bi-telephone me-2"></i>
          <a className="btn-link" href={`tel:${info.phone}`}>
              {info.phone}
          </a>
        <ul className="contact-info list-unstyled">
          <li className="mb-4">

          </li>
          {/*<li>
            <i className="text-primary fs-4 align-middle bi bi-envelope me-2"></i>
            <a className="btn-link" href={`mailto:${info.email}`}>
              {info.email}
            </a>
          </li>*/}
          <li className="mb-4 text-dark">
            {/*<i className="text-primary fs-4 align-middle bi bi-geo-alt me-2"></i>{" "}*/}
            <MapSection   adresssrc={info.adresssrc} />

          </li>
        </ul>
      </div>
    ))

};

export default ContactInfo;
