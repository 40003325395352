import React from "react";
import {Container, Row, Col, Card, CardImg} from "reactstrap";
const teamMembersConvivencia = [
  {
    id: 1,
    name: "César Augusto Zapata Ceballos",
    position: "Colaborador principal",
  },
  {
    id: 2,
    name: "Juan David Gómez Cartagena",
    position: "Colaborador principal",
  },
  {
    id: 3,
    name: "Norbey García Gaviria",
    position: "Colaborador suplente",
  },
  {
    id: 4,
    name: "Jerónimo Torres Suárez",
    position: "Colaborador suplente",
  },
  {
    id: 5,
    name: "Jerónimo Córdoba Torres Suárez",
    position: "Empleador principal (Presidente)",
  },
  {
    id: 6,
    name: "Luisa Fernanda García Ospina",
    position: "Empleador principal (Secretaria)",
  },
  {
    id: 7,
    name: "Aris Ramírez Barrera",
    position: "Empleador suplente",
  },
  {
    id: 8,
    name: "Olga Segunda Vargas Ely",
    position: "Empleador suplente",
  },
];
const teamMembersCpas = [
  {
    id: 1,
    name: "Jonatan David Bedoya Herrera",
    position: "Colaborador principal",
  },
  {
    id: 2,
    name: "Cresmer Alonso Soto Berrio",
    position: "Colaborador principal",
  },
  {
    id: 3,
    name: "Gilberto Fernando Tamayo Correa",
    position: "Colaborador suplente",
  },
  {
    id: 4,
    name: "Cesar Augusto Zapata Ceballos",
    position: "Colaborador suplente",
  },
  {
    id: 5,
    name: "Hernan David Rosso Rueda",
    position: "Empleador principal (Presidente)",
  },
  {
    id: 6,
    name: "Keila Beatriz Quintana Negrete",
    position: "Empleador principal (Secretaria)",
  },
  {
    id: 7,
    name: "Maria Gladys Ruiz Manco",
    position: "Empleador suplente",
  },
  {
    id: 8,
    name: "Julian Shirley Santiago Peña",
    position: "Empleador suplente",
  },
];
const teamMembersBrigadista = [
  {
    id: 1,
    name: "Luis Alberto Villamizar González",
    position: "Director COE",
    sede:'Sede administrativa',
  },
  {
    id: 2,
    name: "Luisa Fernanda García Ospina",
    position: "Comunicaciones",
    sede:'Sede administrativa',
  },
  {
    id: 3,
    name: "Olga Lucía Montes Córdoba",
    position: "Responsable SST",
    sede:'Sede administrativa',
  },
  {
    id: 4,
    name: "María Gladis Ruiz Manco",
    position: "Primeros Auxilios",
    sede:'Sede administrativa',
  },
  {
    id: 5,
    name: "Liliana Patricia Gamboa Córdoba",
    position: "Primeros Auxilios",
    sede:'Sede administrativa',
  },
  {
    id: 6,
    name: "Aris Ramírez Barrera",
    position: "Control de Incendios",
    sede:'Sede administrativa',
  },
  {
    id: 7,
    name: "Osneider Antonio Bejarano Córdoba",
    position: "Control de Incendios",
    sede:'Sede administrativa',
  },
  {
    id: 8,
    name: "Jairo Alonso Moreno Jaramillo",
    position: "Coordinador de Evacuacion y Rescate",
    sede:'Sede administrativa',
  },
  {
    id: 9,
    name: "Hernán David Rosso Rueda",
    position: "Coordinador de Evacuacion y Rescate",
    sede:'Sede administrativa',
  },
  {
    id: 10,
    name: "Edinson Emilio Márquez Fornaris",
    position: "Brigadista Integral",
    sede:'Sede Terminal Apartado',
  },
  {
    id: 11,
    name: "Carmen del Carmen Peinado Vargas",
    position: "Brigadista Integral",
    sede:'Sede Terminal Apartado',
  },
  {
    id: 12,
    name: "Keila Beatriz Quintana Negrete",
    position: "Brigadista Integral",
    sede:'Sede Terminal Apartado',
  },
  {
    id: 13,
    name: "Carolina García Echeverry",
    position: "Brigadista Integral",
    sede:'Sede Terminal Apartado',
  },
  {
    id: 14,
    name: "Nancy Eugenia Macías Borja",
    position: "Comunicaciones",
    sede:'Sede Arboletes',
  },
  {
    id: 15,
    name: "Jhon Jairo Torres Suárez",
    position: "Primeros Auxilios",
    sede:'Sede Arboletes',
  },
  {
    id: 16,
    name: "Freider Peña Banquet",
    position: "Primeros Auxilios",
    sede:'Sede Arboletes',
  },
  {
    id: 17,
    name: "Eduar Darío Andrade Flórez",
    position: "Control de Incendios",
    sede:'Sede Arboletes',
  },
  {
    id: 18,
    name: "Claudia Patricia Marsiglia Martínez",
    position: "Control de Incendios",
    sede:'Sede Arboletes',
  },
  {
    id: 19,
    name: "Luis Fernando Viloria Naar",
    position: "Coordinador de Evacuacion y Rescate",
    sede:'Sede Arboletes',
  },
  {
    id: 20,
    name: "Ever Manuel Padilla Herrera",
    position: "Coordinador de Evacuacion y Rescate",
    sede:'Sede Arboletes',
  },
  {
    id: 21,
    name: "Carmen del Carmen Peinado Vargas",
    position: "Brigadista Integral",
    sede:'Sede Terminal Montería',
  },
  {
    id: 22,
    name: "Keila Beatriz Quintana Negrete",
    position: "Brigadista Integral",
    sede:'Sede Terminal Montería',
  },
  {
    id: 23,
    name: "Carolina García Echeverry",
    position: "Brigadista Integral",
    sede:'Sede Terminal Montería',
  },
];
function Team() {
  return (
    <div>
      <section className="pb-11 p-0">
        <Container>
          <Row>
            <div className="row justify-content-center text-center mb-6  ">
              <Col xs="12" lg="8">
                <div>
                  <h2 className="font-w-6">COPAS</h2>
                </div>
              </Col>
            </div>
              {teamMembersCpas.map((member) => (
                  <Col key={member.id} lg="4" md="12" className="mb-4  ">
                    <Card className="hover-translate bg-white shadow px-3 pt-4 pb-5 rounded-4">
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                            {member.name}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                          <span className="text-muted">{member.position}</span>
                        </h6>
                      </div>
                    </Card>
                  </Col>
              ))}
          </Row>
          <Row>
            <div className="row justify-content-center text-center mb-6 mt-4">
              <Col xs="12" lg="8">
                <div>
                  <h2 className="font-w-6">Comité de Convivencia</h2>
                </div>
              </Col>
            </div>
              {teamMembersConvivencia.map((member) => (
                  <Col key={member.id} lg="4" md="12" className="mb-4 ">
                    <Card className="hover-translate bg-white shadow px-3 pt-4 pb-5 rounded-4">
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                            {member.name}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                          <span className="text-muted">{member.position}</span>
                        </h6>
                      </div>
                    </Card>
                  </Col>
              ))}
          </Row>
          <Row>
            <div className="row justify-content-center text-center mb-6 mt-4">
              <Col xs="12" lg="8">
                <div>
                  <h2 className="font-w-6">Brigadistas de la Cooperativa Integral de Transportadores de Urabá - COOINTUR</h2>
                </div>
              </Col>
            </div>
              {teamMembersBrigadista.map((member) => (
                  <Col key={member.id} lg="4" md="12" className="mb-4 ">
                    <Card className="hover-translate bg-white shadow px-3 pt-4 pb-5 rounded-4">
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                            {member.name}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                          <span className="text-muted">{member.sede}</span>
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 me-2">
                          <span className="text-muted">{member.position}</span>
                        </h6>
                      </div>
                    </Card>
                  </Col>
              ))}
          </Row >
        </Container>
      </section>
    </div>
  );
}

export default Team;
