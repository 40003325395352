import React from "react";  // eslint-disable-line
import HeroBanner2 from "../../Components/heroBanner/HeroBanner2";
import SectionServicios from "../../Components/feature2/SectionServicios";
import About2 from "../../Components/about/About2";
import Contact from "../../Components/contact/Contact";

function index2() {
  return (
    <div className="page-wrapper">
      <HeroBanner2 />
      <div className="page-content">
        <SectionServicios />
        <About2 />
        {/*<Testimonial2 />*/}
        {/*<Faq />*/}
        {/*<Team />*/}
        {/*<Blog />*/}
        <Contact />
      </div>
    </div>
  );
}

export default index2;
