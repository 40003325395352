import React from "react";
import { Col } from "reactstrap";

import imgbus from "../../assets/images/servicios/bus-tr.jpg"
import imgEncmienda from "../../assets/images/servicios/encomienda.jpg"
import imgEspecial from "../../assets/images/servicios/especial.jpg"
const FeatureCard = ({ id, title, description, image }) => {
  return (
    <Col lg="4" md="6" className={id % 2 === 1 ? "mt-5 " : ""} >
      <div className="p-6 mt-2 bg-white rounded-2 f-icon-hover  " style={{boxShadow:'5px 5px 5px 0px rgba(0,0,0,0.3)'}}>
        <div>
          <div className="mb-6">
            { image === 'trasnporte' &&
              <img className="img-fluid w-100" src={imgbus} alt=""/>
            }
            {
              image == 'especial' &&
                <img className="img-fluid w-100" src={imgEspecial} alt="" />
            }
            {
              image == 'encomienda' &&
              <img className="img-fluid w-100" src={imgEncmienda} alt="" />
            }
          </div>
          <h4 className="mb-3 ">{title}</h4>
          <p className="mb-4 ">{description}</p>
          <a className="btn-arrow" href="https://coointur.gotien.com/pqrsPublic/create" target="_blank"></a>
        </div>

      </div>
    </Col>
  );
};

export default FeatureCard;
