import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import SectionServicios from "../../Components/feature2/SectionServicios";

function Servicios() {
  const firstBreadcrumb = { label: "Pages", link: "/contact-us-2" };
  const secondBreadcrumb = {
    label: "Servicios",
    link: "/servicios",
    active: true,
  };
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Servicios"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <SectionServicios />
    </div>
  );
}

export default Servicios;
