import React from "react";
import { Col, Container, Row } from "reactstrap";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Contact from "../../Components/contact/Contact";

import ImgMapaProcesos from "../../assets/images/mapa_procesos/mapa_procesos.png"

function Sgi() {
  const firstBreadcrumb = { label: "Pages", link: "/sgi" };
  const secondBreadcrumb = {
    label: "Sgi",
    link: "/sgi",
    active: true,
  };
  return (
    <div>
      <div className="page-content text-justify">
          <Page_Heading
              title="Sistema de Gestión Integral"
              firstBreadcrumb={firstBreadcrumb}
              secondBreadcrumb={secondBreadcrumb}
          />
          <div className="page-content mb-11">
              <section className="p-0">
                  <Container>
                      <Row className="align-items-center" >
                          <Col xs={12} lg={12} className="pt-10   ">
                              <h2 className="color-primary-coointur text-center font-w-6 mb-10">
                                  Nuestros Valores
                              </h2>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      LIDERAZGO:
                                  </h6>
                                  Hacemos nuestro trabajo con motivación promoviendo un buen ambiente laboral  y participando en el logro de las metas y objetivos de la empresa.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      RESPONSABILIDAD:
                                  </h6>
                                  Prestamos nuestros servicios de transporte y desarrollamos labores de manera responsable basándonos en la seguridad, generación de confianza y cumpliendo las normas y procedimientos de tránsito y transporte y de la empresa.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      INNOVACIÓN:
                                  </h6>
                                  Nos adaptamos fácilmente a los cambios organizacionales y proponemos ideas y nuevas formas para el mejoramiento de la prestación del servicio.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      SOLIDARIDAD:
                                  </h6>
                                  En Coointur nos caracterizamos por el sentido de colaboración y apoyo con nuestros compañeros, usuarios y partes interesadas de la empresa, en busca de beneficios mutuos y sociales.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      BUEN TRATO:
                                  </h6>
                                  Brindamos un buen trato a los usuarios, jefes, compañeros y partes interesadas basado en el respeto y la generación de confianza.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      HONESTIDAD:
                                  </h6>
                                  Prestamos un servicio de transporte con transparencia y cuidando la integridad y bienes de clientes, usuarios y partes interesadas.
                              </p>
                              <p className="lead mb-4">
                                  <h6 className="color-primary-coointur font-w-6">
                                      CALIDEZ:
                                  </h6>
                                  Nuestro actuar tiene el propósito de generar empatía con usuarios y demás partes interesadas, caracterizándose por ofrecer un trato cordial y humano.
                              </p>

                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0 ">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur text-center font-w-6 mb-10" >
                                  Política Integral
                              </h2>
                              <p className="lead mb-4">
                                  La Cooperativa Integral de Transportadores de Urabá - COOINTUR está comprometida con la prestación de servicio público de transporte terrestre automotor de pasajeros en las modalidades de: intermunicipal, especial y de turismo, mediante:
                              </p>
                              <p className="lead mb-4">
                                  La identificación, eliminación, control, mitigación y evaluación de los peligros y de los riesgos, estableciendo sus respectivos controles proporcionando condiciones de trabajo seguras y saludables, para la prevención de lesiones y deterioro de la salud relacionados con el trabajo y que sea apropiado al propósito, tamaño y contexto de la organización. Adicionalmente, las oportunidades para desarrollar en el Sistema de Gestión Integral.
                              </p>
                              <p className="lead mb-4">
                                  El compromiso de la dirección para asegurar los recursos humanos, técnicos, tecnológicos, económicos y demás que sean necesarios para la planeación, implementación, evaluación y mejora continua del desempeño del sistema de gestión integral y el establecimiento y logro de los objetivos, contexto y direccionamiento estratégico del Sistema de Gestión Integral que nos permitan ser eficaces para el crecimiento organizacional.
                              </p>
                              <p className="lead mb-4">
                                  El cumplimiento de requisitos legales: de calidad, de seguridad y salud en el trabajo, ambientales y otros determinados por la organización y partes interesadas, teniendo como base los principios cooperativos y del transporte; garantizando los requisitos de todas las partes interesadas y un ambiente laboral que promueve de manera permanente el bienestar físico, mental y social de las personas de la organización, contratistas, visitantes y partes interesadas, proporcionando condiciones de trabajo seguras y saludables.
                              </p>
                              <p className="lead mb-4">
                                  El fortalecimiento de las competencias del personal que aseguren el óptimo desarrollo de los procesos y mejoramiento continuo, mediante la implementación de planes y programas, promoviendo la consulta y participación activa de todas las partes interesadas y comités, de la organización.
                              </p>
                              <p className="lead mb-4">
                                  La realización de actividades de planificación, ejecución, seguimiento y control para la satisfacción de cliente y partes interesadas y generación de una cultura de seguridad vial.
                              </p>

                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 justify-content-center">
                              <h2 className="color-primary-coointur text-center font-w-6 mb-10">
                                  Mapa de procesos
                              </h2>
                              <img src={ImgMapaProcesos} className="w-100 p-2" alt=""/>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur text-center font-w-6 mb-10">
                                  Objetivos Integrales
                              </h2>
                              <p className="lead mb-4 ">
                                  1. Desarrollar actividades de promoción y prevención de lesiones y deterioro de la salud relacionado con el trabajo, tendiente a eliminar o minimizar la materialización de los riesgos laborales identificados, asociados al cumplimiento del objeto  social.
                              </p>
                              <p className="lead mb-4">
                                  2. Garantizar el compromiso y liderazgo de la dirección con el aseguramiento y disponibilidad de los recursos humanos,  económicos, técnicos y tecnológicos para el mantenimiento y mejora del sistema de gestión integral de manera eficaz  y crecimiento de la Cooperativa.
                              </p>
                              <p className="lead mb-4">
                                  3. Cumplir con los requisitos establecidos para la organización mediante su revisión, aplicación y evaluación  que permita a la organización el cumplimiento de su objeto social de manera eficaz, minimizando la ocurrencia de eventos no deseados.
                              </p>
                              <p className="lead mb-4">
                                  4. Capacitar continuamente a los colaboradores y partes interesadas  fomentando el desarrollo integral y fortaleciendo la responsabilidad, funciones y autoridades de sus cargos.
                              </p>
                              <p className="lead mb-4">
                                  5. Brindar Un servicio de transporte de pasajeros que genere confianza y seguridad a los usuarios y partes interesadas  cumpliendo con los planes de ruta y tiempos establecidos, para la satisfacción de los clientes y  partes interesadas.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="p-0 ">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur text-center font-w-6 mb-10" >
                                  Política Integral
                              </h2>
                              <p className="lead mb-4">
                                  La Cooperativa Integral de Transportadores de Urabá - COOINTUR está comprometida con la prestación de servicio público de transporte terrestre automotor de pasajeros en las modalidades de: intermunicipal, especial y de turismo, mediante:
                              </p>
                              <p className="lead mb-4">
                                  La identificación, eliminación, control, mitigación y evaluación de los peligros y de los riesgos, estableciendo sus respectivos controles proporcionando condiciones de trabajo seguras y saludables, para la prevención de lesiones y deterioro de la salud relacionados con el trabajo y que sea apropiado al propósito, tamaño y contexto de la organización. Adicionalmente, las oportunidades para desarrollar en el Sistema de Gestión Integral.
                              </p>
                              <p className="lead mb-4">
                                  El compromiso de la dirección para asegurar los recursos humanos, técnicos, tecnológicos, económicos y demás que sean necesarios para la planeación, implementación, evaluación y mejora continua del desempeño del sistema de gestión integral y el establecimiento y logro de los objetivos, contexto y direccionamiento estratégico del Sistema de Gestión Integral que nos permitan ser eficaces para el crecimiento organizacional.
                              </p>
                              <p className="lead mb-4">
                                  El cumplimiento de requisitos legales: de calidad, de seguridad y salud en el trabajo, ambientales y otros determinados por la organización y partes interesadas, teniendo como base los principios cooperativos y del transporte; garantizando los requisitos de todas las partes interesadas y un ambiente laboral que promueve de manera permanente el bienestar físico, mental y social de las personas de la organización, contratistas, visitantes y partes interesadas, proporcionando condiciones de trabajo seguras y saludables.
                              </p>
                              <p className="lead mb-4">
                                  El fortalecimiento de las competencias del personal que aseguren el óptimo desarrollo de los procesos y mejoramiento continuo, mediante la implementación de planes y programas, promoviendo la consulta y participación activa de todas las partes interesadas y comités, de la organización.
                              </p>
                              <p className="lead mb-4">
                                  La realización de actividades de planificación, ejecución, seguimiento y control para la satisfacción de cliente y partes interesadas y generación de una cultura de seguridad vial.
                              </p>

                          </Col>
                      </Row>
                  </Container>
              </section>
          </div>

        <Contact />
      </div>
    </div>
  );
}

export default Sgi;
