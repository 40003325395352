import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Contact from "../../Components/contact/Contact";

import ImgHistory1 from "../../assets/images/historia/480-1.jpg"
import ImgHistory2 from "../../assets/images/historia/480-2.jpg"
import ImgMision1 from "../../assets/images/mision/480-3.jpg"

function SobreNostros() {
  const firstBreadcrumb = { label: "Inicio", link: "/" };
  const secondBreadcrumb = {
    label: "Sobre nosotros",
    link: "/quienes-somos",
    active: true,
  };
  return (
    <div>
      <div className="page-content text-justify">
          <Page_Heading
              title="Quiénes somos"
              firstBreadcrumb={firstBreadcrumb}
              secondBreadcrumb={secondBreadcrumb}
          />
          <div className="page-content">
              <section className="py-4 p-0">
                  <Container>
                      <Row className="align-items-center" >
                          <Col xs={12} lg={12} className="pt-0  ">
                              <p className="lead mb-4 ">
                                  La cooperativa COOINTUR, es una organización sin ánimo de lucro, del sector de la Economía solidaria. Habilitada para la prestación del servicio de transporte de pasajeros por carretera en las modalidades Especial e intermunicipal
                              </p>
                              <p className="lead mb-4">
                                  Coointur ha experimentado una transformación enorme convirtiéndose en una empresa reconocida y querida en la región de Urabá. En los últimos 4 años nuestro crecimiento ha sido del 20%. Mientras que el sector transporte a nivel Nacional estuvo por debajo del 4%. Situación que ha permitido un desarrollo sostenido.
                              </p>
                              <p className="lead mb-4">
                                  Este resultado ha sido producto de la organización y del trabajo de cada uno de los colaboradores que hacen parte de la empresa. Coointur se constituye en una de las opciones a nivel regional, conectando los departamentos de Córdoba y choco con Antioquia.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="p-0">
                  <Container>
                      <Row className="align-items-center" >
                          <Col xs={12} lg={6} className="pt-10">
                              <h2 className="color-primary-coointur font-w-6">
                                  HISTORIA
                              </h2>
                              <p className="lead mb-4">
                                  COOINTUR, fue creada en el Municipio de Necocli - Antioquia el 14 de febrero de 1983, por 49 asociados. Todos ellos pequeños transportadores de la Zona Norte del Urabá Antioqueño, con el nombre de Cooperativa Integral de Transportadores Rurales de Urabá. Solo hasta el año de 1996 cambio de nombre para pasar a ser hoy Cooperativa Integral de Transportadores de Urabá “COOINTUR”.
                              </p>
                              <p className="lead mb-4">
                                  Inicio sus operaciones por autorización del Extinto Intra (Instituto Nacional del Transporte- Regional Antioquia Chocó, mediante resolución 004325 del 08 de mayo de 1987, en las rutas Arboletes – San Juan de Urabá, Arboletes – Candelaria, Arboletes - la Trinidad, Arboletes – el Carmelo, Necocli - Pueblo Nuevo y Necocli – Changas. Posteriormente el Ministerio le autorizo servir las rutas: Apartadó – San Pedro de Urabá, Apartadó – Chigorodó, Apartadó – Turbo, Apartadó - Belén de Bajirá, Necocli – San Juan de Urabá, Tierra Alta – San Pedro de Urabá, Montería - Apartadó.
                              </p>
                              <p className="lead mb-4">
                                  Mediante resolución 0254, del 03 de Julio de 2001, se le otorga habilitación, para operar como empresa de transporte Público Automotor de pasajeros por carretera y por medio de la resolución número 00449 del 20 de septiembre de 2001, fue habilitada para prestar servicio de transporte Público de pasajeros en la modalidad de Servicios Especiales; también se encuentra habilitada para operar en los Municipios de Arboletes, Necoclí, San Pedro de Urabá, Mutatá, San Juan de Urabá y Riosucio.
                              </p>
                              <p className="lead mb-4">
                                  COOINTUR a lo largo de la existencia ha acumulado una gran experiencia en la actividad del Transporte Público Automotor de pasajeros por carretera en la zona de Urabá, Bajo Atrato, Medio y Alto Sinú, garantizando a los usuarios del servicio una cómoda, segura y rápida movilización. La estabilidad de sus rutas le ha dado el privilegio de ser reconocida como una empresa líder de la Industria del Transporte en Urabá.
                              </p>
                          </Col>
                          <Col xs={12} lg={6} className="pt-0 justify-content-center">
                              <img src={ImgHistory1} className="w-100 p-2 pb-10" alt=""/>
                              <img src={ImgHistory2} className="w-100 p-2" alt=""/>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0 ">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={6} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur font-w-6">
                                  MISIÓN
                              </h2>
                              <p className="lead mb-4">
                                  Somos una empresa del sector de la economía solidaria, habilitada para prestar servicios de transporte terrestre de pasajeros y servicios especiales que cuenta con vehículos seguros, personal capacitado comprometido con la empresa.
                              </p>

                          </Col>
                          <Col xs={12} lg={6} className="pt-0 justify-content-center">
                              <img src={ImgMision1} className="w-100 p-2" alt=""/>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={6} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur font-w-6">
                                  VISIÓN
                              </h2>
                              <p className="lead mb-4">
                                  Para el 2025, ser una empresa líder en el servicio de transporte terrestre de pasajeros, reconocidos por su prosperidad y por ofrecer servicios con calidad, seguridad y confiabilidad para la satisfacción de los usuarios y partes interesadas.
                              </p>

                          </Col>
                          <Col xs={12} lg={6} className="pt-0 justify-content-center">
                              <img src={ImgMision1} className="w-100 p-2" alt=""/>
                          </Col>
                      </Row>
                  </Container>
              </section>  <section className="mt-5">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur font-w-6">
                                  FILOSOFÍA
                              </h2>
                              <p className="lead mb-4">
                                  Mejorar la calidad de vida de nuestros asociados a través de la prestación del servicio de Transporte público de pasajeros y actividades inherentes al mismo.
                              </p>

                          </Col>
                      </Row>
                  </Container>
              </section>
          </div>

        <Contact />
      </div>
    </div>
  );
}

export default SobreNostros;
