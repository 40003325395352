import React from "react";
import { Container, Row, Col } from "reactstrap";
import FeatureCard from "./FeatureCard";

function SectionServicios() {


  const features = [
    {
      title: "TRANSPORTE TERRESTE",
      to: '',
      description:
        "Ofrecemos el Servicio Público de Transporte Terrestre Automotor de Pasajeros por Carretera, en diversas modalidades como Camperos, Automóviles, Camionetas y Microbuses. Contamos con 14 agencias ubicadas estratégicamente en los departamentos de Antioquia, Chocó, Córdoba y Bolívar.",
      image: "trasnporte",
    },
    {
      title: "TRANSPORTE ESPECIALES",
      description:
        "Transporte Público Terrestre Automotor de Pasajeros en la Modalidad de contratación especial a través de cómodas Camionetas climatizadas, URVAN, DIESEL STATION WAGON y Microbuses; brindamos servicios de Turismo, Escolar y Transporte de Asalariados, transportándolos a cualquier parte del país.",
      image: "especial",
    },
    {
      title: "CARGA",
      description:
        "Servicios Corporativos de Encomiendas: Ofrecemos soluciones de encomiendas y mensajería directa con la empresa a través de suscripciones a convenios o contratos.",
      image: "encomienda",
    },
  ];
  return (
    <section id="servicios" className="  position-relative overflow-hidden z-index-1" style={{backgroundColor: "rgb(98 181 98)"}}>
        <Container className="container">
          <Row className="justify-content-center text-center mb-6">
            <Col lg={8} className="col-12">
              <h2 className="font-w-6 text-white">
                Nuestros servicios
              </h2>
            </Col>
          </Row>
          <Row className="gx-5 HOLO">
            {features.map((feature, index) => (
              <FeatureCard
                key={feature.title}
                title={feature.title}
                description={feature.description}
                image={feature.image}
                id={index}
              />
            ))}
          </Row>
        </Container>
        <div className="position-absolute animation-1 w-100">
          <lottie-player
            src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json"
            background="transparent"
            speed="1"
            //style="width: auto; height: auto;"
            loop
            autoplay
          ></lottie-player>
        </div>
      </section>
  );
}

export default SectionServicios;
