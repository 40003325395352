import React from "react";
import { Col, Container, Row } from "reactstrap";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Contact from "../../Components/contact/Contact";


function LineasEstrategicas() {
  const firstBreadcrumb = { label: "Pages", link: "/lineas-estrategicas" };
  const secondBreadcrumb = {
    label: "Líneas Estratégicas",
    link: "/lineas-estrategicas",
    active: true,
  };
  return (
    <div>
      <div className="page-content text-justify">
          <Page_Heading
              title="Líneas Estratégicas"
              firstBreadcrumb={firstBreadcrumb}
              secondBreadcrumb={secondBreadcrumb}
          />
          <div className="page-content mb-11">
              <section className="p-0">
                  <Container>
                      <Row className="align-items-center" >
                          <Col xs={12} lg={12} className="pt-10   ">
                              <h5 className="color-primary-coointur font-w-6">
                                  El Cliente: Razón de Ser de la Empresa
                              </h5>
                              <p className="lead text-justify mb-4">
                                  La empresa ha estado interiorizando el concepto moderno centrado en el cliente, enfocándose en dos aspectos principales: la atención y la calidad del servicio. Nuestro personal, tanto operativo como administrativo, recibe capacitación periódica para mejorar sus habilidades. Además, se han llevado a cabo importantes eventos en colaboración con la Fundación Cinfes, así como temas cuidadosamente seleccionados y preparados por la empresa.
                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  El desarrollo del personal es un medio para generar cambios significativos.
                              </h5>
                              <p className="lead text-justify mb-4">El personal operativo se capacita a través del SENA con un curso denominado "OPERARIO DE EQUIPO AUTOMOTOR". Mediante esta estrategia, se abordan módulos que comprenden diversas competencias, tales como: lenguaje, ética y valores, cultura del servicio, dimensión ambiental, negociación de conflictos, liderazgo y trabajo en equipo, orientación turística, primeros auxilios, conducción a la defensiva y desvare automotriz. Además, se ha incorporado un último módulo sobre el plan estratégico de seguridad vial.
                                  <br/>
                                  <br/>
                                  La empresa cuenta con un plan de capacitaciones para implementar sus políticas, objetivos y estrategias.</p>
                              <h5 className="color-primary-coointur font-w-6">
                                  Estructura financiera como medio articulador del proceso.
                              </h5>
                              <p className="lead text-justify mb-4">
                                  Somos cuidadosos en el manejo financiero; nuestras proyecciones buscan mantener un equilibrio entre los gastos necesarios y evitar cualquier gasto superfluo. Siempre nos esforzamos por ser austeros para garantizar la operación normal de la empresa.
                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  La innovación y la tecnología como elementos diferenciadores.
                              </h5>
                              <p className="lead text-justify mb-4">
                                  A través de la innovación y el desarrollo de una herramienta tecnológica, hemos iniciado un proyecto para ampliar la difusión de los servicios de nuestra empresa a través de la plataforma web. Con esta herramienta, los usuarios pueden acceder a información sobre los itinerarios de nuestros servicios, lo que les permite planificar y programar sus viajes de manera más efectiva.                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  Parque automotor suficiente y seguro.
                              </h5>
                              <p className="lead text-justify mb-4">
                                  El mejoramiento continuo de nuestro parque automotor y su constante renovación contribuyen esencialmente a garantizar servicios seguros, ágiles y oportunos.
                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  Balance social positivo
                              </h5>
                              <p className="lead text-justify mb-4">
                                  Se trabajará más en brindar servicios a los asociados, ofreciendo créditos rápidos para el mejoramiento del parque automotor, auxilios educativos, y apoyo solidario en caso de accidentes. Además, se proporcionará acompañamiento para que puedan acceder a créditos destinados a la renovación del parque automotor.
                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  Análisis interno.
                              </h5>
                              <p className="lead text-justify mb-4">
                                  COOINTUR es una empresa con domicilio principal en la ciudad de Apartadó, desde donde se coordina toda la operación del transporte a nivel regional. Actualmente, se vive una calma en cuanto al orden público. La empresa no ha sufrido el flagelo de la delincuencia en muchos años, y los escasos episodios de llamadas extorsivas de grupos al margen de la ley han sido oportunamente controlados.
                                  <br/>
                                  <br/>
                                  Lo que más impacta en los costos operativos es el combustible, seguido de los costos de mantenimiento y otros gastos. La informalidad en el servicio nos afecta, pero existe un compromiso de las autoridades para controlarla. Nuestros conductores reciben capacitación de forma permanente y se realizan reuniones participativas para evaluar los servicios.
                              </p>
                              <h5 className="color-primary-coointur font-w-6">
                                  Análisis externo.
                              </h5>
                              <p className="lead text-justify mb-4">
                                  Coointur cuenta con muy buenas referencias a nivel comercial. Se ha ido construyendo una imagen de favorabilidad en torno a la empresa. Nos consideran una empresa responsable y organizada. Nos hemos convertido en líderes del sector, ocupando espacios importantes. Somos pioneros en el tema de capacitación de nuestros conductores a través del SENA. Hemos estado atentos al desarrollo que ha tenido la zona en materia de vías. La pavimentación nos ha obligado a trabajar más en la modernización de la flota, y los grandes proyectos en materia de construcción de puertos en la zona hacen que la preparación sea cada vez mayor, contando con un personal altamente calificado para atender a toda clase de viajeros.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur   font-w-6 mb-7">
                                  Implementación de la estrategia.
                              </h2>
                              <p className="lead text-justify mb-4 ">
                                  <span>1. </span>
                                  <span className="color-primary-coointur">ECONOMICOS: </span>
                                  <span>
                                   En los últimos 5 años, hemos experimentado un excelente desempeño económico. Somos competitivos en cuanto a precios y tarifas, y hemos logrado gestionar de manera eficiente nuestros costos operativos y administrativos.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>2. </span>
                                  <span className="color-primary-coointur">FINANCIEROS: </span>
                                  <span>
                                      Las proyecciones financieras se han cumplido cabalmente. Las obligaciones con terceros son mínimas y hemos manejado las finanzas de manera eficiente.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>3. </span>
                                  <span className="color-primary-coointur">TECNOLOGICOS: </span>
                                  <span>
                                      Contamos con recursos tecnológicos acordes a nuestras actividades. En los últimos años, hemos equipado nuestras sedes operativas con tecnología de última generación, incluyendo equipos de cómputo, escáneres y alcoholímetros. Además, nos encontramos en la etapa de desarrollo de una plataforma web que atenderá eficientemente las necesidades operativas de la empresa.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>4. </span>
                                  <span className="color-primary-coointur">HUMANOS: </span>
                                  <span>
                                      Hemos estado capacitando a nuestro personal operativo y administrativo. La mayoría de nuestros operarios han sido graduados por el SENA como profesionales en equipos automotores. Contamos con personal experimentado que trata a nuestros usuarios con amabilidad. Nuestro personal administrativo se ha formado en diversas disciplinas relacionadas con la actividad administrativa. Además, contamos con un equipo en línea de colaboradores expertos en áreas como derecho, salud ocupacional, ingeniería de sistemas, contaduría, entre otros.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>5. </span>
                                  <span className="color-primary-coointur">INFRAESTRUCTURA y EQUIPAMIENTO: </span>
                                  <span>
                                    Coointur cuenta con un parque automotor de 120 unidades destinadas a brindar servicios especiales e intermunicipales, lo que nos permite satisfacer la demanda del servicio de acuerdo con los planes de operación de la empresa. Además, disponemos de 4 sedes propias para gestionar y operar nuestras actividades.
                                  </span>
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur   font-w-6 mb-7">
                                  Recursos de la organización
                              </h2>
                              <p className="lead text-justify mb-4 ">
                                  La mejora de la capacitación se logrará mediante la integración y el compromiso de todos los empleados text-justify de la empresa. Esto implicará designar líderes con tareas específicas y personas responsables para asegurar la correcta ejecución del plan de capacitación. Además, cada líder de proceso tendrá metas asignadas.
                                  <br />
                                  <br />
                                  La gerencia jugará un papel fundamental al acompañar el proceso de monitoreo, seguimiento y evaluación de resultados.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
              <section className="mt-10 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 align-middle">
                              <h2 className="color-primary-coointur   font-w-6 mb-7">
                                  Objetivos estratégicos
                              </h2>
                              <p className="lead text-justify mb-4 ">
                                  <span>1. </span>
                                  <span className="color-primary-coointur">El cliente como razón de ser de la empresa: </span>
                                  <span>
                                      Mantener a nuestros clientes y usuarios satisfechos es nuestro objetivo principal, logrado al brindarles una atención de calidad y un servicio de transporte de pasajeros excepcional, lo que nos convierte en su opción preferida.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>2. </span>
                                  <span className="color-primary-coointur">El Desarrollo del Personal como Medio para Producir Cambios: </span>
                                  <span>
                                      Garantizar el desarrollo de las competencias del personal de la empresa es esencial para mejorar su rendimiento en el cumplimiento de funciones y responsabilidades. Para lograrlo, implementamos programas de capacitación y formación empresarial que buscan aumentar su satisfacción y motivación en el entorno laboral.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>3. </span>
                                  <span className="color-primary-coointur">La estructura financiera como medio articulador del proceso: </span>
                                  <span>
                                      El objetivo es mantener un crecimiento económico anual mínimo del 10% a través del manejo eficiente de los recursos de la empresa y la prestación del servicio. Esto permitirá cumplir con las obligaciones laborales y contractuales de la empresa.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>4. </span>
                                  <span className="color-primary-coointur">La innovación y la tecnología como elementos diferenciadores: </span>
                                  <span>
                                      Contar con una plataforma tecnológica que permita y potencie la interacción con los clientes y partes interesadas, mediante el acceso a la información y la utilización de los servicios de la cooperativa, generará una diferenciación en el mercado y facilitará las operaciones.
                                  </span>
                              </p>
                              <p className="lead text-justify mb-4">
                                  <span>5. </span>
                                  <span className="color-primary-coointur">Parque automotor suficiente y seguro: </span>
                                  <span>
                                    Cumplir con los requisitos de capacidad transportadora mediante la adquisición y mantenimiento del parque automotor que nos permita además de ser competitivos, brindar un servicio de calidad, seguridad y confort
                                  </span>
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </section>
          </div>

        <Contact />
      </div>
    </div>
  );
}

export default LineasEstrategicas;
