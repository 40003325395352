import React, { useState } from "react";
import {Container, Row, Col, Button, NavLink, NavItem} from "reactstrap";
import "reactjs-popup/dist/index.css";
import LottiePlayer from "../player";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Bg from "../../assets/js/lottie/hero2bg.json";

import portada from "../../assets/images/img-portada-coointur.jpg";
function HeroBanner2() {

  return (
    <div>
      <section className="px-lg-12 pb-5 pt-5 position-relative" id="bannerPortada" >
        <div className="overflow-hidden position-relative bg-light-3 z-index-2   py-10" style={{backgroundImage: `url(${portada})`}}>
          <Container className="z-index-1">
            <Row className="align-items-center">
              <Col xs={12} lg={6} className="mb-8 mb-lg-0">
                {/*<LottiePlayer src={portada} />*/}
              </Col>
              <Col xs={12} lg={6} className="ps-lg-8">
                <h1 className="font-w-6 mb-5" style={{color:'#fff'}}>
                  Cooperativa Integral de Transportadores de Urabá
                </h1>
                <div>
                  <Button  className="me-3 rounded-2 btn btn-success box-shadow" >
                      <NavLink
                               href={`https://coointur.gotien.com/pqrsPublic/create`}
                               target={`_blank`}
                      >
                        Comencemos
                      </NavLink>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="position-absolute animation-1 w-100">
            <LottiePlayer src={Bg} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default HeroBanner2;
