import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
      <Container>
        <Row className="row">
          <Col className="col-12">
            <div
              className=" p-4 p-lg-10 rounded-2 text-center"
              style={{
                backgroundColor: 'rgb(98 181 98)',
                // backgroundColor: 'rgb(4 53 25)',
                // backgroundImage: `url(${BgImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h2 className="text-white mb-5 font-w-7">
                Confían en nosotros <br />

                  <p className=" m-0 text-center align-self-center   text-light ">

                  </p>
              </h2>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                DIRECCION DE IMPUESTOS Y ADUANAS NACIONALES – DIAN
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center   text-light  ">
                                MUNICIPIO DE NECOCLI
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end  border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                MUNICIPIO DE ARBOLETES
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0  ">
                            <p className=" m-0 text-center  align-self-center  text-light">
                                MUNICIPIO DE SAN PEDRO DE URABA
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                BATALLON DE INFANTERIA NÚMERO 47 “GENERAL FRANCISCO DE PAULA VELEZ”
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center   text-light  ">
                                POLICIA NACIONAL
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end  border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                COMFAMA EPS
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0  ">
                            <p className=" m-0 text-center  align-self-center  text-light">
                                SELVASALUD EPS
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                SOLSALUD EPS
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center   text-light  ">
                                SALUD DARIEN IPS
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end  border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                ASOCIACION INDIGENA DEL CAUCA EPS
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0  ">
                            <p className=" m-0 text-center  align-self-center  text-light">
                                CONTRANSUROCCIDENTE
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                EMDISALUD
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end border-white">
                            <p className=" m-0 text-center align-self-center   text-light  ">
                                SAVIA SALUD EPS
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0 border-end  border-white">
                            <p className=" m-0 text-center align-self-center  text-light  ">
                                AUDITORIA AMBIENTAL
                            </p>
                        </div>
                        <div className="col p-1 align-middle d-flex justify-content-center m-0  ">
                            <p className=" m-0 text-center  align-self-center  text-light">
                                CONSULTORIA COLOMBIANA (CONCOL)
                            </p>
                        </div>
                    </div>
                </div>
              <p className="text-light fs-4 text-center  m-10 mb-3">
                    Viaja comodamente
              </p>
                <a className="btn rounded-2 bg-secundary-coointur btn-success box-shadow" href="https://coointur.gotien.com/pqrsPublic/create" target="_blank">
                    Comencenos
                </a>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
