import React from "react";
import { Col, Container, Row } from "reactstrap";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Contact from "../../Components/contact/Contact";

import ImgEstructura from "../../assets/images/estructura/estructura.jpg"

function EstructuraOrganizacional() {
  const firstBreadcrumb = { label: "Pages", link: "/estructura-organizacional" };
  const secondBreadcrumb = {
    label: "Estructura Organizacional",
    link: "/estructura-organizacional",
    active: true,
  };
  return (
    <div>
      <div className="page-content text-justify">
          <Page_Heading
              title="Estrutura Organizacional"
              firstBreadcrumb={firstBreadcrumb}
              secondBreadcrumb={secondBreadcrumb}
          />
          <div className="page-content mb-11">
              <section className="m-0 p-0">
                  <Container>
                      <Row className="align-items-center align-middle" >
                          <Col xs={12} lg={12} className="pt-0 justify-content-center">
                              <img src={ImgEstructura} className="w-100 p-2" alt=""/>
                          </Col>
                      </Row>
                  </Container>
              </section>
          </div>

        <Contact />
      </div>
    </div>
  );
}

export default EstructuraOrganizacional;
