import React, { useState, useEffect } from "react";
import { useNavigate,   } from "react-router-dom";

import logo from "../../assets/images/logos/logo-web.png"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  } from "reactstrap";

function Header() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNav = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navItems = [
    {
      label: "Inicio",
      to:"/",
      dropdown: false,
    },
    {
      label: "Oficinas",
      dropdown: false,
      to: "/taquillas",
    },

    {
      label: "Servicios",
      to: "/servicios",
      dropdown: false,
    },
   /* {
      label: "Preguntas frecuentes",
      to: "/feature-accordion",
      dropdown: false,
      items: [
        { label: "Accordion", to: "" },
        { label: "Blog", to: "/feature-blog" },
        { label: "Counter", to: "/feature-counter" },
        { label: "Feature Box", to: "/feature-icon-box" },
        { label: "Hero Banner", to: "/feature-hero" },
        { label: "Price Table", to: "/feature-pricing" },
        { label: "Team", to: "/feature-team" },
        { label: "Testimonial", to: "/feature-testimonial" },
      ],
    },*/

    {
      label: "Empresa",
      to: "/",
      dropdown: true,
      items: [
        { label: "Quiénes somos", to: "/quienes-somos" },
        { label: "Estructura Organizacional", to: "/estructura-organizacional" },
        { label: "Líneas estratégicas", to: "/lineas-estrategicas" },
        { label: "Equipo", to: "/team" },
      ],
    },
    {
      label: "SGI",
      to: "/sgi",
      dropdown: false,
    },
    /*{
      label: "Blogs",
      to: "/blog-card",
      dropdown: false,
      items: [
        { label: "Blog Card", to: "/" },
        { label: "Blog Listing 1", to: "/blog-listing" },
        { label: "Blog Listing 2", to: "/blog-listing-2" },
        { label: "Blog Single", to: "/blog-single" },
      ],
    },*/
    {
      label: "Contactanos",
      // label: "PQRS",
      dropdown: false,
      to: "https://coointur.gotien.com/pqrsPublic/create",
      etiqueta_a: true,
      target: "_blank",
    },
    {
      label: "Itinerario",
      dropdown: false,
      to: "https://coointur.gotien.com/itinerarios/publico",
      etiqueta_a: true,
      target: "_blank",
    }

  ];
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(navItems.length).fill(false)
  );

  const toggleDropdown = (index) => {
    const newArray = [...dropdownOpen];
    newArray[index] = !newArray[index];
    setDropdownOpen(newArray);
  };
  const navigate = useNavigate();

  const handleLinkClick = (to) => {
    navigate(to, { replace: true });
    window.location.reload(); // Refresh the page
  };
  return (
    <div className="">
      <header className="site-header" >
        <div id="header-wrap">
          <Container className="container" >
            <Row className="row" >
              {/* <!--menu start--> */}
              <Col className="col">
                <Nav className="navbar navbar-expand-lg p-2 px-4 shadow  bg-white" style={{boxShadow:'5px 5px 5px 0px rgba(0,0,0,0.3) !important'}}>
                  <NavbarBrand href="/" className="navbar-brand logo">
                    <img src={logo} style={{width:'150px'}} alt="" />
                  </NavbarBrand>

                  <NavbarToggler onClick={toggleNav} className="me-2" />
                  <Collapse className="navbar-collapse" isOpen={!isCollapsed}>
                    <Nav className="navbar-nav mx-auto">
                      {navItems.map((item, index) => {

                        if (item.etiqueta_a){
                          return (
                            <NavItem key={index}>
                              <NavLink key={index}
                                href={`${item.to}`}
                                target={`${item.target?item.target:''}`}
                              >
                                {item.label}
                              </NavLink>
                            </NavItem>
                          );
                        }
                        if (!item.dropdown){
                          return (
                            <NavItem key={index}>
                              <NavLink
                                // href={`${item.to}`}
                                onClick={() =>  handleLinkClick(item.to)}
                              >
                                {item.label}
                              </NavLink>
                            </NavItem>
                          );
                        }

                        return (
                        <Dropdown
                          key={item.label}
                          nav
                          isOpen={dropdownOpen[index]}
                          toggle={() => toggleDropdown(index)}
                        >
                          <DropdownToggle nav caret>
                            {item.label}
                          </DropdownToggle>
                          <DropdownMenu>
                            {item.items.map((subItem) => (
                              <DropdownItem
                                key={subItem.label}
                                className=""
                                onClick={() => handleLinkClick(subItem.to)}
                              >
                                {subItem.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      )
                      })}
                    </Nav>
                  </Collapse>

                  <Nav className="navbar-nav ml-auto align-items-center">
                    <div className="d-flex align-items-center">
                      <NavItem key={2}>
                        <NavLink
                            href="https://coointur.gotien.com/"
                            target="_blank"
                            active
                        >
                          Empleados
                        </NavLink>
                      </NavItem>
                    </div>
                  </Nav>
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
      </header>


    </div>
  );
}

export default Header;
