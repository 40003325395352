import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./Components/header/Header";
import Index2 from "./Pages/index2/index2";
import Footer from "./Components/footer/Footer";
import SobreNostros from "./Pages/about-us/SobreNostros";
import Team from "./Pages/team-page/TeamPage";
import TeamSingle from "./Pages/team-single/TeamSingle";
import Maintenance from "./Pages/maintenance/Maintenance";
import ComingSoon from "./Pages/coming-soon/ComingSoon";
import { ToastContainer,   } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PortFolioSingle from "./Pages/portfolio/PortFolioSingle";
import AccordionPage from "./Pages/accordion/AccordionPage";
import BlogPage from "./Pages/blog-page/BlogPage";
import BlogSingle from "./Components/blog/BlogSingle";
import CounterPage from "./Pages/counter-page/CounterPage";
import FeatureBox from "./Pages/feature-box/FeatureBox";
import HeroBannerPage from "./Pages/hero-banner-page/HeroBannerPage";
import TestiMonialPage from "./Pages/testimonial-page/TestiMonialPage";
import BlogCard from "./Pages/blog-card/BlogCard";
import BlogList1 from "./Pages/blog-list-1/BlogList1";
import BlogList2 from "./Pages/blog-list-1/BlogList2";
import BackToTop from "./Components/BackToTop";
import Contact from "./Pages/contact/Contatct";
import Taquillas from "./Pages/contact/Taquillas";
import ErrorPage from "./Pages/error-page/ErrorPage";
import TAndC from "./Pages/term-And-Condition/tAndC";
import Privacy from "./Pages/term-And-Condition/privacy";
import { useEffect, useState } from "react";
import Sgi from "./Pages/sgi/Sgi";
import EstructuraOrganizacional from "./Pages/estructura-organizacional/EstructuraOrganizacional";
import LineasEstrategicas from "./Pages/lineas-estrategicas/LineasEstrategicas";
import Servicios from "./Pages/servicios/Servicios";


function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 50); // Change the delay as needed

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const isSpecialRoute =
    location.pathname.includes("maintenance") ||
    location.pathname.includes("coming-soon") ||
    location.pathname.includes("error-404");
  // console.log(isSpecialRoute);

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading ? (
        <div id="ht-preloader">
          <div className="loader clear-loader">
            <img src="images/loader.gif" alt="" />
          </div>
        </div>
      ) : (
        // Rest of your JSX code
        <>
          {isSpecialRoute ? (
            <Routes>
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/error-404" element={<ErrorPage />} />
            </Routes>
          ) : (
            <div className="page-wrapper">
              <Header />
              <Routes>
                <Route path="/" element={<Index2 />} />
                <Route path="/quienes-somos" element={<SobreNostros />} />
                <Route path="/sgi" element={<Sgi />} />
                <Route path="/taquillas" element={<Taquillas />} />
                <Route path="/servicios" element={<Servicios />} />
                <Route path="/team" element={<Team />} />
                <Route path="/team-single" element={<TeamSingle />} />
                <Route path="/portfolio-single" element={<PortFolioSingle />} />
                <Route path="/feature-accordion" element={<AccordionPage />} />
                <Route path="/estructura-organizacional" element={<EstructuraOrganizacional />} />
                <Route path="/lineas-estrategicas" element={<LineasEstrategicas />} />
                <Route path="/feature-blog" element={<BlogPage />} />
                <Route path="/blog-single" element={<BlogSingle />} />
                <Route path="/feature-counter" element={<CounterPage />} />
                <Route path="/feature-icon-box" element={<FeatureBox />} />
                <Route path="/feature-hero" element={<HeroBannerPage />} />
                <Route
                  path="/feature-testimonial"
                  element={<TestiMonialPage />}
                />
                <Route path="/blog-card" element={<BlogCard />} />
                <Route path="/blog-listing" element={<BlogList1 />} />
                <Route path="/blog-listing-2" element={<BlogList2 />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/terms-and-conditions" element={<TAndC />} />
                <Route path="/privacy-policy" element={<Privacy />} />

                {/* <Route path="/:mediaType/:id" element={<Details />} />
        <Route path="/search/:query" element={<SearchResult />} />
        <Route path="/explore/:mediaType" element={<Explore />} />
        <Route path="*" element={<PageNotFound />} /> */}
              </Routes>
              <Footer />
              <BackToTop />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
